/* ============================================================================
   app.js
   ========================================================================= */

/**
 * Main script bundle used throughout the website
 *
 * @author: Mark Croxton, mcroxton@hallmark-design.co.uk
 * @copyright: Hallmark Design
 */

/* eslint-disable no-unused-vars */

// example of importing one of your own modules:
// import * as nav from "./modules/navigation";

// SVG icon sprites
import SvgIconSprite from './modules/svgIconSprite';
new SvgIconSprite();

// Lazy loaded images
// @see https://github.com/aFarkas/lazysizes
import 'lazysizes';

// Lazy loaded scripts
// @see https://github.com/djpogo/lazy-scripts
import LazyScripts from 'lazy-scripts';
new LazyScripts();

// Publishes a 'resizeDone' event triggered when window resizing is complete.
// Components can subscribe to it to update their state.
// Also sets a --windowHeight css variable for use in CSS, instead of 100vh
import "./modules/resizeDone";

import 'slick-carousel';

$(".carousel").slick({
    arrows: false,
    speed: 1000,
    infinite: true,
    adaptiveHeight: true
});

$( ".blogFilter" ).on('change', function() {
    window.location.href = this.value;

  });


 //ticking machine
 var percentTime;
 var tick;
 var time = .05;
 var progressBarIndex = 0;

 $('.pagination .progressBar').each(function(index) {
     var progress = "<div class='inProgress inProgress" + index + "'></div>";
     $(this).html(progress);
 });

 function startProgressbar() {
     resetProgressbar();
     percentTime = 0;
     tick = setInterval(interval, 10);
 }

 function interval() {
     if (($('.carousel .slick-track div[data-slick-index="' + progressBarIndex + '"]').attr("aria-hidden")) === "true") {
         progressBarIndex = $('.carousel .slick-track div[aria-hidden="false"]').data("slickIndex");
         startProgressbar();
     } else {
         percentTime += 1 / (time + 5);
         $('.inProgress' + progressBarIndex).css({
             width: percentTime + "%"
         });
         if (percentTime >= 100) {
             $('.carousel').slick('slickNext');
             progressBarIndex++;
             if (progressBarIndex > 2) {
                 progressBarIndex = 0;
             }
             startProgressbar();
         }
     }
 }

 function resetProgressbar() {
     $('.inProgress').css({
         width: 0 + '%'
     });
     clearInterval(tick);
 }
 startProgressbar();
 // End ticking machine

 $('.item').click(function () {
     clearInterval(tick);
     var goToThisIndex = $(this).find("span").data("slickIndex");
     $('.carousel').slick('slickGoTo', goToThisIndex, false);
     startProgressbar();
 });

// jQuery in a module example
import * as test from "./modules/test.js";

test.say("Is it me you're looking for?");

// Vue example
import Vue from "vue";
Vue.config.productionTip = false;
// const main = new Vue({
//     el: "#test-vue"
// });

window.showSearchForm = (val) => {
    if(val){
        $('#search-form').slideDown();
        $('#search-form-input').focus();
    } else {
        $('#search-form').slideUp();
    }
}

window.toggleMenu = () => {
    $('#mobile-nav').toggleClass('translate-x-full')
}

window.hideThanks = function() {
    $('#thanks').fadeOut();
}
$(document).keyup((e) => {
    if (e.key === "Escape") {
        $('#search-form').slideUp();
   }
});

if ($('#thanks').length) {
    setTimeout(()=> {

                $('#thanks').fadeOut();
            },5000)
}

// Accept HMR
// https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}

const cyrb53 = function(str, seed = 0) {
   let h1 = 0xdeadbeef ^ seed,
      h2 = 0x41c6ce57 ^ seed;
   for (let i = 0, ch; i < str.length; i++) {
      ch = str.charCodeAt(i);
      h1 = Math.imul(h1 ^ ch, 2654435761);
      h2 = Math.imul(h2 ^ ch, 1597334677);
   }
   h1 = Math.imul(h1 ^ h1 >>> 16, 2246822507) ^ Math.imul(h2 ^ h2 >>> 13, 3266489909);
   h2 = Math.imul(h2 ^ h2 >>> 16, 2246822507) ^ Math.imul(h1 ^ h1 >>> 13, 3266489909);
   return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};

let clientIP = "{$_SERVER['REMOTE_ADDR']}";
let validityInterval = Math.round (new Date() / 1000 / 3600 / 24 / 4);
let clientIDSource = clientIP + ";" + window.location.host + ";" + navigator.userAgent + ";" + navigator.language + ";" + validityInterval;
let clientIDHashed = cyrb53(clientIDSource).toString(16);

(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
})(window,document,'script','//www.google-analytics.com/analytics.js','ga');

ga('create', 'YOUR-GA-TRACKING-CODE', {
   'storage': 'none',
   'clientId': clientIDHashed
});
ga('set', 'anonymizeIp', true);
ga('send', 'pageview');
